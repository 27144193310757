<template>
  <div>
    <b-row class="my-2">
      <b-col md="12">
        <b-card
          no-body
          class="mb-0 p-2 d-flex justify-content-center"
        >
          <b-row>
            <b-col
              md="8"
              class="d-flex align-items-center mb-2 mb-xs-5 mb-lg-0 mb-xl-0"
            >
              <strong>{{ $t('src.views.pages.inside.deliveryorder.weA') }}</strong>
              <img
                :src="require('@/assets/images/payments/visa.png')"
                style="padding: 0px 10px"
              >
              <img
                :src="require('@/assets/images/payments/mastercard.png')"
              >
            </b-col>
            <b-col md="4">
              <div
                class="d-flex flex-column"
              >
                <b-row class="mb-1">
                  <b-col cols="12">
                    <b-form-group
                      label="Voucher Code"
                    >
                      <v-select
                        v-model="selectedVoucher"
                        label="code_name"
                        :options="vouchers"
                        :reduce="voucher => voucher.uuid"
                        @input="applyVoucher"
                      >
                        <span
                          slot="no-options"
                        >
                          No Available Voucher
                        </span>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col
                    md="12"
                    class="d-flex justify-content-between"
                  >
                    <h4 class="mb-0">
                      {{ $t('src.views.pages.inside.deliveryorder.subT') }}
                    </h4>
                    <h4
                      class="mb-0"
                    >
                      {{ $helpers.formatTotal(charges.subTotal) }}
                    </h4>
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col
                    md="12"
                    class="d-flex justify-content-between"
                  >
                    <h4 class="mb-0">
                      {{ $t('src.views.pages.inside.deliveryorder.totalDiscounts') }}
                    </h4>
                    <h4
                      class="mb-0"
                    >
                      {{ $helpers.formatTotal(charges.totalDiscount) }}
                    </h4>
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col
                    md="12"
                    class="d-flex justify-content-between"
                  >
                    <h4 class="mb-0">
                      {{ $t('src.views.pages.inside.deliveryorder.pickUC') }}
                    </h4>
                    <h4
                      class="mb-0"
                    >
                      {{ $helpers.formatTotal(charges.pickupCharge) }}
                    </h4>
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col
                    md="12"
                    class="d-flex justify-content-between"
                  >
                    <h4 class="mb-0">
                      {{ $t('src.views.pages.inside.deliveryorder.taxC') }}
                    </h4>
                    <h4
                      class="mb-0"
                    >
                      {{ charges.taxCharge || 0 }}%
                    </h4>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                    md="12"
                    class="d-flex justify-content-between"
                  >
                    <h4 class="mb-0 text-primary py-2">
                      {{ $t('src.views.pages.inside.deliveryorder.grandT') }}
                    </h4>
                    <h4
                      class="mb-0  py-2"
                    >
                      {{ $helpers.formatTotal(charges.grandTotal) }}
                    </h4>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="6"
                  >
                  <b-button
                      variant="success"
                      class="w-100 mt-1"
                      type="button"
                      :disabled="orderCount === 0 || processPayment"
                      @click.prevent="$emit('proceedToPayMent', 'LOGPOINT')"
                    >
                      <b-spinner
                        v-if="processPayment"
                        class="mr-50"
                        small
                      />
                      Pay with LogPoints
                      <span v-if="orderCount > 0">({{ orderCount }})</span>
                    </b-button>
                    </b-col>
                    <b-col
                    md="6"
                  >
                    <b-button
                      variant="warning"
                      class="w-100 mt-1"
                      type="button"
                      :disabled="orderCount === 0 || processPayment"
                      @click.prevent="$emit('proceedToPayMent', 'SENANGPAY')"
                    >
                      <b-spinner
                        v-if="processPayment"
                        class="mr-50"
                        small
                      />
                      Pay with Senangpay
                      <span v-if="orderCount > 0">({{ orderCount }})</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import axios from '@axios'

export default {
  name: 'MyCharges',
  components: {
    vSelect,
  },
  props: {
    charges: {
      type: Object,
      required: true,
    },
    totalDiscount: {
      type: Number,
      default: 0,
    },
    orderCount: {
      type: Number,
      default: 0,
      required: true,
    },
    processPayment: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedVoucher: null,
      vouchers: [],
    }
  },
  created() {
    this.fetchVouchers()
  },
  methods: {
    async fetchVouchers() {
      const response = await axios.get('/user-vouchers')
      if (response.status === 200) {
        this.vouchers = response.data
      }
    },
    applyVoucher() {
      this.$emit('applyVoucher', this.selectedVoucher)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
